import React from 'react'

import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import Layout from '../components/Layout'
import Seo from "../components/Seo"
import Breadcrumbs from '../components/Breadcrumbs'


const ArticleTemplate = ({ data, pageContext, location }) => {
  const article = data.strapiArticle
  const { previous, next } = pageContext

  return (
    <Layout pageData={data.strapiBlog}>
      <Seo title={article.title} article />
      <Breadcrumbs
        firstName="Blog"
        firstSlug="blog"
        secondName={article.title}
      />

      <div className="container">
        <GatsbyImage
          image={article.cover.localFile.childImageSharp.gatsbyImageData}
          alt={article.title}
        />

        <div className="box mt-4">
          <h1 className="title is-2">{article.title}</h1>
          <p className="subtitle is-5">{article.published_at}</p>
          <div className="content is-medium">
            <p className="is-medium">
              <Markdown
                children={article.content}
                remarkPlugins={[remarkGfm]}
              />
            </p>
          </div>
        </div>

        <div className="columns is-multiline">
          {article.images.map((image, i) => (
            <div className="column is-half" key={i}>
              <GatsbyImage
                image={image.localFile.childImageSharp.gatsbyImageData}
                alt={image.alternativeText ? image.alternativeText : ""}
                style={{ margin: '1rem 0' }}
              />
              <figcaption>{image.caption ? image.caption : ""}</figcaption>
            </div>
          ))}
        </div>

      </div>

      <nav
        className="pagination is-centered"
        role="navigation"
        aria-label="pagination"
      >
        {previous && (
          <Link to={`/blog/${previous.slug}`} className="pagination-previous">
            {previous.title}
          </Link>
        )}
        {next && (
          <Link to={`/blog/${next.slug}`} className="pagination-next">
            {next.title}
          </Link>
        )}
      </nav>

    </Layout>
  )
}
export default ArticleTemplate

export const pageQuery = graphql`
  query ArticleByID($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    strapiBlog {
      title
      subtitle
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
    strapiArticle(id: { eq: $id }) {
      id
      published_at(formatString: "dddd, D MMMM YYYY")
      updated_at(formatString: "dddd, D MMMM YYYY")
      title
      content
      slug
      tags {
        name
      }
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
      }
      images {
        caption
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1600
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
  }
`
